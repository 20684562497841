
import { Component, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import VueStrong from '@/VueStrong'

import { callToActions } from '@/helpers/data'

@Component
export default class StepPreview extends VueStrong {
  @Prop({ default: () => [] }) shopifyProducts: any[]
  thumbnailDialog = null

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }

  get selectedPictures(): { product: string; src: string }[] {
    return this.$store.state.launch.selectedPictures || []
  }
  get selectedProducts() {
    return this.$store.state.launch.selectedProducts || []
  }

  get selectedVideos(): { product: string; src: string }[] {
    return this.$store.state.launch.selectedVideos || []
  }

  get videoProduct() {
    if (!this.thumbnailDialog) return []
    return this.selectedProducts.find(p => p._id === this.thumbnailDialog.product)
  }

  get settings() {
    return this.$store.state.launch.settings || []
  }

  get pictures() {
    return this.selectedPictures.map(p => ({ ...p, setting: this.settings.find(s => s.product._id === p.product) }))
  }

  get videos() {
    return this.selectedVideos.map(p => ({ ...p, setting: this.settings.find(s => s.product._id === p.product) }))
  }

  get fbImages() {
    const images = this.$store.state.launch.selectedAdImages
    return images.map(i => {
      return { ...i, setting: this.settings.find(s => s.product._id === i.product) }
    })
  }

  get fbVideos() {
    const videos = this.$store.state.launch.selectedAdVideos
    return videos.map(v => {
      return { ...v, setting: this.settings.find(s => s.product._id === v.product) }
    })
  }

  get generalVariations() {
    return this.$store.state.launch.generalSetting.adCreative.generalVariations
  }

  get urlTagsVariations() {
    return this.$store.state.launch.generalSetting.adCreative.urlTagsVariations
  }

  get variations() {
    const templateVar = this.generalVariations.flatMap(general => {
      const link = general.link
      const title = general.title
      const description = general.description
      const body = general.body
      const urlTags = this.urlTagsVariations.map(urlTag => urlTag)
      return urlTags.map(urlTag => ({ link, title, description, body, urlTags: urlTag }))
    })
    return templateVar
  }

  parseCTA(cta: string) {
    return callToActions.find(c => c.value === cta).key
  }

  formatText(product: string, text: string) {
    const shopifyProduct = this.shopifyProducts.find(p => p.ykProduct + '' === product + '')
    if (!shopifyProduct) return text

    return text
      .replace('{{ product_title }}', shopifyProduct.title)
      .replace('{{ link }}', shopifyProduct.storefrontUrl + '/product/' + shopifyProduct.handle)
      .replace('{{ fb_link }}', shopifyProduct.storefrontUrl + '/fb/product/' + shopifyProduct.handle)
      .replace('{{ shopify_link }}', shopifyProduct.shopifyDomain + '/products/' + shopifyProduct.handle)
      .replace('{{ product_subtitle }}', shopifyProduct.subtitle)
      .replace('{{ bulletpoints }}', shopifyProduct.bulletpoints)
      .replace('{{ price }}', shopifyProduct.variants[0].price)
      .replace(
        '{{ compare_at_price }}',
        shopifyProduct.variants[0].compare_at_price || shopifyProduct.variants[0].price
      )
      .replace(
        '{{ discount_value }}',
        `${
          parseFloat(shopifyProduct.variants[0].compare_at_price || shopifyProduct.variants[0].price) -
          parseFloat(shopifyProduct.variants[0].price)
        }`
      )
      .replace(
        '{{ discount_percentage }}',
        `${Math.ceil(
          (100 *
            (parseFloat(shopifyProduct.variants[0].compare_at_price || shopifyProduct.variants[0].price) -
              parseFloat(shopifyProduct.variants[0].price))) /
            parseFloat(shopifyProduct.variants[0].compare_at_price || shopifyProduct.variants[0].price)
        )}`
      )
  }

  updateVideoThumbnail(videoSrc: string, pictureSrc: string) {
    const selectedVideos = cloneDeep(this.selectedVideos)
    for (const video of selectedVideos) if (video.src === videoSrc) video.thumbnail = pictureSrc

    this.$store.dispatch('launch/setSelectedVideos', selectedVideos)
    this.thumbnailDialog = null
  }
}
