
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'

@Component({
  components: {
    SettingsCard
  }
})
export default class AdSetScheduling extends VueStrong {
  @Prop({ default: false }) editableValue!: Partial<yakkyo.IFacebookAdSet>

  startDatePickerModal = false
  endDatePickerModal = false
}
