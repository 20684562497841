
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
@Component
export default class AdCreativeVariationsBanner extends VueStrong {
  backgroundColor = '#cff1da'
  mainColor = '#33cc71'

  get totalAdCreatives() {
    return this.$store.getters['launch/totalAdCreativesCurrentVariations']
  }

  get adCreativeVariationLimit() {
    return 100
  }

  get totalWidth() {
    return (this.totalAdCreatives / this.adCreativeVariationLimit) * 100
  }
}
