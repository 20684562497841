
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class SpecificSettings extends VueStrong {
  @Prop({ default: false }) specificSettingsOn!: boolean
  @Prop({ default: null }) product!: yakkyo.IProduct | null // TODO: should it be a string?

  toggleSpecificSettings(newVal: boolean) {
    this.$emit('toggleSpecificSettings', newVal)
  }
}
