
import { Component, Watch } from 'vue-property-decorator'
import StepCampaignVariation from '@/modules/launch/components/StepCampaignVariation.vue'
import StepAdSet from '@/modules/launch/components/StepAdSet.vue'
import StepAdCreative from '@/modules/launch/components/StepAdCreative.vue'
import StepChooseMedias from '@/modules/launch/components/StepChooseMedias.vue'
import StepPreview from '@/modules/launch/components/StepPreview.vue'
import ProductList from '@/modules/product/components/ProductList.vue'
import SelectLaunchType from '@/modules/launch/components/SelectLaunchType.vue'
import VariationMaxDialog from '@/modules/adset/components/VariationMaxDialog.vue'
import { cloneDeep } from 'lodash'
import { ISetting } from '@/modules/launch/store/state'
import VueStrong from '@/VueStrong'

@Component({
  components: {
    StepPreview,
    ProductList,
    SelectLaunchType,
    StepAdCreative,
    StepAdSet,
    StepCampaignVariation,
    VariationMaxDialog,
    StepChooseMedias
  }
})
export default class CampaignFlow extends VueStrong {
  loading = false
  snackbarSuccess = false
  snackbarError = false
  shopifyProducts = []
  showMaxVariationDialog = false
  maxVariationDialogHeader = ''
  maxVariationDialogContent = ''

  editingProduct = ''

  launchType: 'template' | 'manual' | null = null
  changeStepError = ''

  @Watch('applySettingsFor')
  onOptionSelect() {
    if (this.applySettingsFor === 'Every product') this.editingProduct = ''
    else this.editingProduct = this.applySettingsFor
  }

  get applySettingsFor() {
    return this.$store.state.launch.applySettingsFor.entity
  }

  get totalAdSets() {
    return this.$store.getters['launch/totalAdSetsCurrentVariations']
  }
  get totalAdCreatives() {
    return this.$store.getters['launch/totalAdCreativesCurrentVariations']
  }

  get step() {
    return this.$store.state.launch.step
  }

  set step(step: number) {
    this.$store.commit('launch/setStep', step)
  }

  get applySettingsOptions() {
    return [{ _id: 'Every product', title: 'Every product' }, ...this.selectedProducts]
  }

  findProductTitle(id: string) {
    const product = this.selectedProducts.find(p => p._id === id)
    if (product) return product.title
    return 'Every product'
  }

  get selectedProducts() {
    return this.$store.state.launch.selectedProducts || []
  }

  get selectedShop() {
    return this.$store.state.launch.shop
  }

  get settings() {
    return this.$store.state.launch.settings || []
  }

  get generalSettings() {
    return this.$store.state.launch.generalSetting
  }

  get selectedPictures(): { product: string; src: string }[] {
    return this.$store.state.launch.selectedPictures || []
  }

  get selectedVideos(): { product: string; src: string }[] {
    return this.$store.state.launch.selectedVideos || []
  }
  get selectedFbImages() {
    return this.$store.state.launch.selectedAdImages || []
  }
  get selectedFbVideos() {
    return this.$store.state.launch.selectedAdVideos || []
  }

  get pages() {
    return this.$store.getters['account/pages']
  }

  get shopifyProductIds() {
    if (!this.selectedShop || !this.selectedShop.products) return []

    return this.settings
      .map(setting => {
        const product = this.selectedShop.products.find(p => p.product === setting.product._id)
        if (!product) return ''
        return product.platformIds[0] // shopify imports do only have 1 id stored
      })
      .filter(id => id)
  }

  get baseProductSettings() {
    const baseSettings = {
      importStatus: 0,
      importError: '',
      specificSettingsOn: {
        campaign: false,
        targeting: false,
        adCreative: false,
        adSet: false
      },
      page: ''
    }
    // TODO: re-evaluate how we're handling those defaults with templates...
    return this.selectedProducts.map(
      sp =>
        ({
          ...baseSettings,
          ...this.generalSettings,
          product: sp,
          campaign: {
            ...this.generalSettings.campaign,
            name: '[C] ' + sp.title
          },
          adCreative: {
            ...this.generalSettings.adCreative,
            name: sp.title
          },
          adSet: {
            ...this.generalSettings.adSet,
            name: '[S] ' + sp.title
          }
        } as ISetting)
    )
  }

  get productIds() {
    const ids = this.$route.query['productId[]'] || this.$route.query.productId || []
    if (typeof ids === 'string') return [ids]
    else return ids
  }

  async created() {
    this.updateSettings()
    await this.getShopifyProducts()
    if (this.productIds && this.productIds.length) await this.getProducts()
  }

  @Watch('selectedShop')
  async onSelectedShopChange() {
    await this.getShopifyProducts()
  }

  @Watch('selectedProducts')
  async onSelectedProductspChange() {
    await this.getShopifyProducts()
    this.updateSettings()
  }

  async getProducts() {
    if (!this.productIds && !this.productIds.length) return
    try {
      const promises = (this.productIds as string[]).map(id => this.$store.state.apiClient.getProduct(id))
      const data = await Promise.all(promises)
      const products = data.map(d => d.data)
      products.forEach(p => this.$store.dispatch('launch/selectProduct', p))
      this.$store.commit('launch/setPreSelectedProducts', products)
      this.$store.commit('launch/setStep', 2)
    } catch (error) {
      console.log(error)
    }
  }

  async getShopifyProducts() {
    try {
      if (!this.selectedShop) return
      if (!this.shopifyProductIds.length) return
      const { data } = await this.$store.state.apiClient.getShopProducts(this.selectedShop._id, this.shopifyProductIds)
      this.shopifyProducts = data
    } catch (error) {
      console.log(error)
    }
  }

  updateSettings() {
    // TODO: check wether a product has been added and apply settings accordingly instead...
    const settings = cloneDeep(
      this.settings.length && this.settings.length === this.selectedProducts.length
        ? this.settings
        : this.baseProductSettings
    )
    this.$store.dispatch('launch/updateSettings', settings)
  }

  async publish() {
    this.loading = true

    try {
      await this.$store.dispatch('launch/publish', this.shopifyProducts)
      this.snackbarSuccess = true
    } catch (e) {
      console.log(e)
      this.snackbarError = true
    }

    if (this.settings.every(s => s.importStatus > 0)) this.step = 6 // TODO: shouldn't this be a recap step or smth?

    this.loading = false
  }

  disableSpecificSettings() {
    this.editingProduct = ''
    this.$store.commit('launch/setApplySettingsFor', 'Every product')
  }

  get stepError() {
    return this.$store.state.launch.changeStepError
  }

  get stepErrorMessage() {
    if (this.stepError === 'products') return 'Please, select at least one product.'
    else if (this.stepError === 'chooseMedia') return 'Please, select at least one media for every product.'
    else return 'Please, fill in all the required fields to continue.'
  }

  get steps() {
    return this.$store.state.launch.steps
  }

  validateStep(step: 'products' | 'campaign' | 'adSets' | 'adCreative' | 'chooseMedia') {
    if (step === 'products') {
      this.$store.commit('launch/setValidationTrigger', 'stepChange')
      return this.$store.commit('launch/setValidStep', { type: 'products', value: { submitted: true, valid: null } })
    }
    if (step === 'campaign') {
      this.$store.commit('launch/setValidationTrigger', 'stepChange')
      return this.$store.commit('launch/setValidStep', { type: 'campaign', value: { submitted: true, valid: null } })
    }
    if (step === 'adSets') {
      this.$store.commit('launch/setValidationTrigger', 'stepChange')
      this.$store.commit('launch/setValidStep', { type: 'adSet', value: { submitted: true, valid: null } })
    }
    if (step === 'adCreative') {
      this.$store.commit('launch/setValidationTrigger', 'stepChange')
      this.$store.commit('launch/setValidStep', { type: 'adCreative', value: { submitted: true, valid: null } })
    }
    if (step === 'chooseMedia') {
      this.$store.commit('launch/setValidationTrigger', 'stepChange')
      this.$store.commit('launch/setValidStep', { type: 'chooseMedia', value: { submitted: true, valid: null } })
    }
  }

  updateApplySettingsFor(productId) {
    this.$store.commit('launch/setValidationTrigger', 'applySettingsFor')
    if (this.step === 2)
      this.$store.commit('launch/setValidStep', {
        type: 'campaign',
        value: { submitted: true, valid: null },
        applySettingsFor: { entity: 'Every product', productId }
      })
    if (this.step === 3)
      this.$store.commit('launch/setValidStep', {
        type: 'adSet',
        value: { submitted: true, valid: null },
        applySettingsFor: { entity: 'Every product', productId }
      })
    if (this.step === 4)
      this.$store.commit('launch/setValidStep', {
        type: 'adCreative',
        value: { submitted: true, valid: null },
        applySettingsFor: { entity: 'Every product', productId }
      })
  }

  snackbarInput(v) {
    if (!v) this.$store.commit('launch/setChangeStepError', '')
  }

  showMaxAdsetVariationReach() {
    this.maxVariationDialogHeader = 'Maximum adset variations reached!'
    this.maxVariationDialogContent =
      'You have already reached the maximum number of adset variations set for this campaign! Change the number of adset variations in the campaign settings in order to use more.'
    this.showMaxVariationDialog = true
  }
  showMaxAdCreativeVariationReach() {
    this.maxVariationDialogHeader = 'Maximum AdCreative variations reached!'
    this.maxVariationDialogContent =
      'You have already reached the maximum number of AdCreative variations set for this campaign! Change the number of AdCreative variations in the campaign settings in order to use more.'
    this.showMaxVariationDialog = true
  }
}
