
import { Component, PropSync, Watch } from 'vue-property-decorator'
import TemplateInput from '@/modules/template/components/TemplateInput.vue'
import VueStrong from '@/VueStrong'

@Component({ components: { TemplateInput } })
export default class AdCreativeForm extends VueStrong {
  @PropSync('value', { type: Object }) editableValue!: Partial<yakkyo.IFacebookAdTemplateAdCreative>

  required = (val: string): boolean | string => !!val || 'Required'

  get submitted() {
    return this.$store.state.launch.steps.adCreative.submitted
  }

  get validationTrigger() {
    return this.$store.state.launch.validationTrigger
  }

  get applySettingsFor() {
    return this.$store.state.launch.applySettingsFor
  }

  @Watch('submitted')
  onSubmit() {
    if (this.submitted) {
      const valid = (this.$refs.form as any).validate()
      if (valid) {
        if (!!this.applySettingsFor.productId && this.validationTrigger === 'applySettingsFor')
          this.$store.commit('launch/setApplySettingsFor', { entity: this.applySettingsFor.productId, productId: '' })
        this.$store.commit('launch/setStep', 5)
      } else {
        if (!!this.applySettingsFor.productId && this.validationTrigger === 'applySettingsFor')
          this.$store.commit('launch/setApplySettingsFor', { entity: this.applySettingsFor.entity, productId: '' })
        this.$store.commit('launch/setChangeStepError', 'adCreative')
      }
      this.$store.commit('launch/setValidStep', { type: 'adCreative', value: { submitted: false, valid } })
    }
  }
}
