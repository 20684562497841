
import { Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import NumberInput from '@/components/NumberInput.vue'
import { debounce } from 'lodash'
import { audienceVariation } from '@/modules/launch/store/state'
import { cloneDeep } from 'lodash'

@Component({ components: { NumberInput, SettingsCard } })
export default class AutocompleteTargetForm extends VueStrong {
  @PropSync('value', { type: Object }) editableValue: Partial<yakkyo.IFacebookAdTemplateAdSet['targeting']>
  @Prop({ default: null }) generalTargeting!: yakkyo.IFacebookAdTemplateAdSet['targeting'] | null
  @Prop({ default: null }) product!: yakkyo.IProduct | null // TODO: should it be a string?
  @Prop({ default: null }) variation!: audienceVariation
  @Prop({ default: null }) index!: number

  locale = 'en'
  locales = []
  localesSearchQuery = ''
  loadingLocales = false
  debounceSearchLocales = debounce(this.searchLocales, 1000)
  interests = []
  interestSearchQuery = ''
  loadingInterests = false
  debounceSearchInterests = debounce(this.searchInterests, 1000)

  interestSearchLocales = ['en', 'it', 'es', 'fr', 'de']
  countryCodes = ['IT', 'US', 'DE', 'NL', 'FR', 'ES', 'GB', 'CA', 'NZ']

  get selectedAdAccount() {
    return this.$store.state.account?.adAccount || null
  }

  @Watch('interestSearchQuery')
  async onInterestSearchQueryChange() {
    await this.debounceSearchInterests()
  }

  @Watch('localesSearchQuery')
  async onLocalesSearchQueryChange() {
    await this.debounceSearchLocales()
  }
  @Watch('variation.interests', { immediate: true, deep: true })
  updateInterests() {
    this.interests = cloneDeep(this.variation.interests)
    this.locales = cloneDeep(this.variation.locales)
  }

  formatNumber(value: string) {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' })
    return formatter.format(Number(value))
  }

  async searchInterests() {
    this.loadingInterests = true
    if (!this.interestSearchQuery || !this.selectedAdAccount?.id) return
    try {
      const { data } = await this.$store.state.apiClient.getInterests(
        this.selectedAdAccount.id,
        this.interestSearchQuery,
        this.locale
      )
      const filtered = data.data
        .filter(obj => !this.interests.find(i => i.id === obj.id))
        .map(d => ({
          ...d,
          audience_size_upper_bound: this.formatNumber(d.audience_size_upper_bound),
          audience_size_lower_bound: this.formatNumber(d.audience_size_lower_bound),
          filter: true
        }))
      this.interests = [...filtered, ...this.interests.map(i => ({ ...i, filter: false }))]
      // this.interests = data.data
    } catch (error) {
      console.log(error)
    }
    this.loadingInterests = false
  }

  async searchLocales() {
    if (!this.localesSearchQuery) return
    this.loadingLocales = true
    try {
      const { data } = await this.$store.state.apiClient.getLocales(this.selectedAdAccount.id, this.localesSearchQuery)
      this.locales = [...data.data.filter(obj => !this.locales.find(i => i.key === obj.key)), ...this.locales]
    } catch (error) {
      console.log(error)
    }
    this.loadingLocales = false
  }

  normalizeText(obj) {
    if (obj) {
      return obj.name + ' (' + obj.audience_size_lower_bound + ' - ' + obj.audience_size_upper_bound + ' )'
    }
    return ''
  }
  updateGeneralSettings() {
    this.$emit('update', this.variation, this.index)
  }
}
