
import { Prop, Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class TargetForm extends VueStrong {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: '' }) header!: string
  @Prop({ default: '' }) content!: string
}
