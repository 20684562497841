
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

// TODO: rename to something more specific. This might be confusing with the other NavMenu
@Component
export default class NavMenu extends VueStrong {
  @Prop({ required: true }) menuItems: { title: string; icon: string; variations?: number }[]
  @Prop({ required: true }) active: number

  onMenuItemClick(index: number) {
    this.$emit('setActive', index)
  }
}
