
import { Component, Watch } from 'vue-property-decorator'
import ProductPictures from '@/modules/product/components/ProductPictures.vue'
import ProductVideos from '@/modules/product/components/ProductVideos.vue'
import NavMenu from '@/components/NavMenu.vue'
import FacebookMedia from './FacebookMedia.vue'
import VueStrong from '@/VueStrong'
import SectionBanner from '@/components/SectionBanner.vue'

@Component({ components: { ProductPictures, ProductVideos, NavMenu, FacebookMedia, SectionBanner } })
export default class StepChooseMedias extends VueStrong {
  step = 0

  get chooseAssetsItems() {
    return [
      {
        title: this.allSelectedMediaCount === 0 ? 'Yakkyo Media' : `Yakkyo Media (${this.allSelectedMediaCount})`,
        icon: 'yakkyo'
      },
      {
        title: this.selectedFbMediaCount === 0 ? 'Facebook Media' : `Facebook Media (${this.selectedFbMediaCount})`,
        icon: 'mdi-facebook'
      }
    ]
  }

  get selectedProducts() {
    return this.$store.state.launch.selectedProducts || []
  }

  get selectedPictures(): { product: string; src: string }[] {
    return this.$store.state.launch.selectedPictures || []
  }

  get selectedVideos(): { product: string; src: string }[] {
    return this.$store.state.launch.selectedVideos || []
  }

  get selectedFbImages() {
    return this.$store.state.launch.selectedAdImages || []
  }

  get selectedFbVideos() {
    return this.$store.state.launch.selectedAdVideos || []
  }

  get allSelectedMediaCount() {
    return this.selectedPictures.length + this.selectedVideos.length
  }

  get selectedFbMediaCount() {
    return this.$store.state.launch.selectedAdImages.length + this.$store.state.launch.selectedAdVideos.length
  }

  changeStep(step: number) {
    this.step = step
  }

  countSelectedMedia(productId: string) {
    const selectedPicturesCount = this.selectedPictures.reduce((count, product) => {
      if (product.product === productId) count++
      return count
    }, 0)
    const selectedVideosCount = this.selectedVideos.reduce((count, product) => {
      if (product.product === productId) count++
      return count
    }, 0)

    return selectedPicturesCount + selectedVideosCount
  }

  countSelectedFbMedia(productId: string) {
    const images = this.$store.state.launch.selectedAdImages.filter(i => i.product === productId).length
    const videos = this.$store.state.launch.selectedAdVideos.filter(v => v.product === productId).length
    return images + videos
  }

  get submitted() {
    return this.$store.state.launch.steps.chooseMedia.submitted
  }

  @Watch('submitted')
  onSubmit() {
    if (this.submitted) {
      const productsWithNoMediaSelected = []
      let step

      this.selectedProducts.forEach(product => {
        const noYakkyoPic = !this.selectedPictures.some(p => p.product === product._id)
        const noYakkyoVid = !this.selectedVideos.some(v => v.product === product._id)
        const noFbPic = !this.selectedFbImages.some(i => i.product === product._id)
        const noFbVid = !this.selectedFbVideos.some(v => v.product === product._id)
        if (noYakkyoPic && noYakkyoVid) step = 0
        else if (noFbPic && noFbVid) step = 1
        if (noYakkyoPic && noYakkyoVid && noFbPic && noFbVid) productsWithNoMediaSelected.push(product.yakkyofySku)
      })
      const valid = productsWithNoMediaSelected.length === 0
      this.$store.commit('launch/setValidStep', { type: 'chooseMedia', value: { submitted: false, valid } })
      if (!valid) {
        this.step = step
        this.$store.commit('launch/setChangeStepError', 'chooseMedia')
      } else this.$store.commit('launch/setStep', 6)
    }
  }
}
