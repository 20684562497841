
import axios from 'axios'
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class ProductVideos extends VueStrong {
  @Prop({ required: true }) product: yakkyo.IProduct
  file = null

  uploadDialog = false

  loading = false
  snackbarSuccess = false
  snackbarError = false
  uploadError = false

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }

  get selectedVideos(): { product: string; src: string }[] {
    return this.$store.state.launch.selectedVideos || []
  }

  get bulkToggleSelect() {
    return this.allVideosSelected ? 'unselect all' : 'select all'
  }

  get allVideosSelected() {
    let res = true
    this.product.videos.forEach(productVideo => {
      if (!this.selectedVideos.some(selectedVideo => selectedVideo.src === productVideo)) res = false
    })
    return res
  }

  async onFileChanged(file, productId: string) {
    try {
      if (!file) return
      this.loading = true
      const formData = new FormData()
      formData.append('file', file)
      const { data } = await axios.post(`${process.env.VUE_APP_MEDIA_BASE_URL}/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': this.$store.state.token }
      })

      this.$store.dispatch('launch/addProductVideo', { productId, src: data.result.url })
      this.snackbarSuccess = true
    } catch (error) {
      console.log(error)
      this.snackbarError = true
      this.uploadError = true
    }
    file = null
    this.loading = false
  }

  closeUploadDialog() {
    this.uploadDialog = false
    this.uploadError = false
  }

  toggleSelectAll(product: yakkyo.IProduct) {
    if (this.allVideosSelected) {
      //select all
      const selectedVideos = this.selectedVideos.filter(v => !product.videos.includes(v.src))
      this.$store.dispatch('launch/setSelectedVideos', selectedVideos)
    } else {
      //unselect all
      const productVideos = product.videos.map(p => ({ product: product._id, src: p }))

      let selected = [...productVideos, ...this.selectedVideos].map(p => JSON.stringify(p))
      selected = [...new Set([...selected])].map(s => JSON.parse(s))

      this.$store.dispatch('launch/setSelectedVideos', selected)
    }
  }

  isSelectedVideo(video: string) {
    return !!this.selectedVideos.find(p => p.src === video)
  }

  selectVideo(video: string, product: string) {
    const selectedVideos = [...new Set([...this.selectedVideos, { src: video, product }])]
    this.$store.dispatch('launch/setSelectedVideos', selectedVideos)
  }

  unselectVideo(video: string) {
    const selectedVideos = [...this.selectedVideos.filter(p => p.src !== video)]
    this.$store.dispatch('launch/setSelectedVideos', selectedVideos)
  }
}
