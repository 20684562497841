import { render, staticRenderFns } from "./SectionBanner.vue?vue&type=template&id=3ad44f11&scoped=true&"
import script from "./SectionBanner.vue?vue&type=script&lang=ts&"
export * from "./SectionBanner.vue?vue&type=script&lang=ts&"
import style0 from "./SectionBanner.vue?vue&type=style&index=0&id=3ad44f11&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ad44f11",
  null
  
)

export default component.exports