
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import { bidStrategies } from '@/helpers/data'
import NumberInput from '@/components/NumberInput.vue'
import SettingsCard from '@/components/SettingsCard.vue'

@Component({
  components: {
    NumberInput,
    SettingsCard
  }
})
export default class AdSetBudget extends VueStrong {
  @Prop({ default: false }) editableValue!: Partial<yakkyo.IFacebookAdSet>

  required = [v => !!v || '*Required']

  get bidStrategies() {
    return bidStrategies
  }
  get cbo() {
    return this.$store.state.launch.generalSetting.campaign.cbo
  }
  get budgetWindow() {
    return this.$store.state.launch.generalSetting.campaign.budgetWindow
  }
  get spendCapLabel() {
    return this.budgetWindow === 'LIFETIME'
      ? `Lifetime Spend CAP (${this.currency})`
      : `Daily Spend CAP (${this.currency})`
  }

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount : null
  }

  get currency() {
    if (!this.selectedAdAccount) return 'USD'
    return this.selectedAdAccount.currency || 'USD'
  }

  updateValue(key, value) {
    this.editableValue[key] = value
    this.$emit('update', this.editableValue)
  }
}
