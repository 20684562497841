
import { Component, Prop, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { ISetting } from '@/modules/launch/store/state'
import VueStrong from '@/VueStrong'

@Component
export default class SettingsMixin extends VueStrong {
  @Prop({ default: '' }) product?: string
  editingSection = '' // 'campaign' | 'adSet' | 'adCreative' | 'targeting'
  editableFields: string[] = []
  // editableSettings: ISetting = null

  get editableSettings(): ISetting {
    if (!this.product) return this.generalSettings
    return this.settings.find(s => s.product._id === this.product)
  }

  set editableSettings(value: ISetting) {
    if (!this.product) this.$store.commit('launch/updateGeneralSetting', value)
    else {
      const settingsToUpdate: ISetting[] = cloneDeep(this.settings)
      const index = settingsToUpdate.findIndex(s => s.product._id === this.product)
      settingsToUpdate[index] = value
      this.$store.dispatch('launch/updateSettings', settingsToUpdate)
    }
  }

  get generalSettings(): ISetting {
    return this.$store.state.launch.generalSetting
  }

  get settings(): ISetting[] {
    return this.$store.state.launch.settings
  }

  // created() {
  //   this.onGeneralSettingsUpdate()
  //   this.onSettingsUpdate()
  // }

  // @Watch('generalSettings', { deep: true })
  // onGeneralSettingsUpdate() {
  //   if (this.product) return
  //   this.editableSettings = cloneDeep(this.generalSettings)
  // }

  // @Watch('product')
  // onProductChange() {
  //   this.onGeneralSettingsUpdate()
  //   this.onSettingsUpdate()
  // }

  // @Watch('settings', { deep: true })
  // onSettingsUpdate() {
  //   if (!this.product) return
  //   this.editableSettings = cloneDeep(this.settings.find(s => s.product._id === this.product))
  // }

  enableSpecificSettings(status: boolean) {
    this.editableSettings.specificSettingsOn[this.editingSection] = status
    this.dispatchSettingUpdate()

    if (!status) {
      const settings = this.settings.map(s => this.applyGeneralSetting(s))
      this.$store.dispatch('launch/updateSettings', settings)
      this.$emit('disableSpecificSettings')
    }
  }

  applyGeneralSetting(setting: ISetting) {
    if (setting.specificSettingsOn[this.editingSection]) return setting

    for (const editableField of this.editableFields) {
      if (editableField.includes('.')) {
        const [parent, child] = editableField.split('.')
        if (child !== 'name') setting[parent][child] = this.generalSettings[parent][child]
      } else {
        if (editableField !== 'name') setting[editableField] = this.generalSettings[editableField]
      }
    }
    return cloneDeep(setting)
  }

  // TODO: double check if now that we ha
  dispatchSettingUpdate() {
    if (this.product) this.updateSettings()
    else this.updateGeneralSetting()
  }

  updateGeneralSetting() {
    this.$store.dispatch('launch/updateGeneralSetting', this.editableSettings)

    const settings = this.settings.map(s => this.applyGeneralSetting(s))
    this.$store.dispatch('launch/updateSettings', settings)
  }

  updateSettings() {
    const settingsToUpdate: ISetting[] = cloneDeep(this.settings)
    const index = settingsToUpdate.findIndex(s => s.product._id === this.product)
    settingsToUpdate[index] = this.editableSettings
    this.$store.dispatch('launch/updateSettings', settingsToUpdate)
  }
}
