
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import SectionBanner from '@/components/SectionBanner.vue'
import { billingEvents, objectives, attributions } from '@/helpers/data'

import AdSetBudget from '@/modules/adset/components/AdSetBudget.vue'
import AdSetScheduling from '@/modules/adset/components/AdSetScheduling.vue'

@Component({ components: { SettingsCard, SectionBanner, AdSetBudget, AdSetScheduling } })
export default class AdSetGeneralForm extends VueStrong {
  @Prop({ required: true }) campaign!: yakkyo.IFacebookAdTemplateCampaign
  @Prop({ default: null }) product!: yakkyo.IProduct | null // TODO: should it be a string?

  required = [v => !!v || '*Required']
  arrayRequried = [v => (!!v && Array.isArray(v) && !!v.length) || '*Required']

  optimization_goal = ''
  bid_amount = ''
  billing_event = ''
  conversion_event = ''

  get editableValue(): Partial<yakkyo.IFacebookAdTemplateAdSet> {
    return this.$store.state.launch.generalSetting.adSet
  }
  get attributions() {
    return attributions
  }

  get isAdSetActive(): boolean {
    return this.editableValue.status === 'ACTIVE'
  }

  set isAdSetActive(newValue: boolean) {
    this.editableValue.status = newValue ? 'ACTIVE' : 'PAUSED'
  }

  get isCBO() {
    return this.campaign.cbo
  }

  get targetObjective() {
    return objectives.find(o => o.value === this.campaign.objective)
  }

  get optiomizationGoals() {
    return this.targetObjective ? this.targetObjective.optimizationGoals : []
  }

  get billingEvents() {
    return billingEvents[this.editableValue.optimization_goal] || []
  }

  get conversionEvents() {
    return this.targetObjective ? this.targetObjective.conversionEvents : []
  }

  get showBid() {
    if (this.campaign.cbo) return this.campaign.bid_strategy !== 'LOWEST_COST_WITHOUT_CAP'
    return this.editableValue.bid_strategy !== 'LOWEST_COST_WITHOUT_CAP'
  }

  get showConversionWindow() {
    return ['OUTCOME_ENGAGEMENT', 'OUTCOME_LEADS', 'OUTCOME_SALES'].includes(this.campaign.objective as string)
  }

  dispatchSettingUpdate(editableValue) {
    this.$emit('update', editableValue)
  }
}
