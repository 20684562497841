
import { Component, PropSync } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import { IUtmTags } from '@/modules/launch/store/state'
import SettingsCard from '@/components/SettingsCard.vue'

@Component({ components: { SettingsCard } })
export default class AdCreativeUtm extends VueStrong {
  @PropSync('value', { type: Object }) variation!: Partial<IUtmTags>
}
