
import { Component } from 'vue-property-decorator'
import { ISetting } from '@/modules/launch/store/state'
import VueStrong from '@/VueStrong'

@Component
export default class ShopSelect extends VueStrong {
  selectedShop: yakkyo.IShop | null = null
  shops: yakkyo.IShop[] = []

  get settings(): ISetting[] {
    return this.$store.state.launch.settings
  }

  async created() {
    this.selectedShop = this.$store.state.launch.shop

    await this.getShops()
  }

  updateSelectedShop() {
    this.$store.dispatch('launch/setShop', this.selectedShop)
  }

  async getShops() {
    try {
      const { data } = await this.$store.state.apiClient.getShops()
      this.shops = data.shops
    } catch (error) {
      console.log(error)
    }
  }
}
