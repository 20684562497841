
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
@Component
export default class AdSetVariationsBanner extends VueStrong {
  backgroundColor = '#cff1da'
  mainColor = '#33cc71'

  get totalAdSets() {
    return this.$store.getters['launch/totalAdSetsCurrentVariations']
  }

  get adSetVariationLimit() {
    return 100
  }

  get totalWidth() {
    return (this.totalAdSets / this.adSetVariationLimit) * 100
  }
}
