
import { Component, Prop, PropSync } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import SectionBanner from '@/components/SectionBanner.vue'
import { cloneDeep } from 'lodash'
import { placementVariation } from '@/modules/launch/store/state'

@Component({ components: { SettingsCard, SectionBanner } })
export default class PlacementsForm extends VueStrong {
  @PropSync('value', { type: Object }) editableValue: Partial<yakkyo.IFacebookAdTemplateAdSet['targeting']>
  @Prop({ default: null }) generalTargeting!: yakkyo.IFacebookAdTemplateAdSet['targeting'] | null
  @Prop({ default: null }) product!: yakkyo.IProduct | null // TODO: should it be a string?

  reachEstimate = ''

  expanded = [0]
  variationObject = {
    manual: false,
    audience_network_positions: [
      { name: 'Classic', value: 'classic', selected: true, kind: 'audience_network_positions' },
      { name: 'Rewarded Video', value: 'rewarded_video', selected: true, kind: 'audience_network_positions' }
    ],
    device_platforms: [
      { name: 'Desktop', value: 'desktop', kind: 'device_platforms', selected: true },
      { name: 'Mobile', value: 'mobile', kind: 'device_platforms', selected: true }
    ],
    facebook_positions: [
      { name: 'Feed', value: 'feed', kind: 'facebook_positions', selected: true },
      { name: 'Right Hand Column', value: 'right_hand_column', kind: 'facebook_positions', selected: true },
      { name: 'Marketplace', value: 'marketplace', kind: 'facebook_positions', selected: true },
      { name: 'Video Feeds', value: 'video_feeds', kind: 'facebook_positions', selected: true },
      { name: 'Story', value: 'story', kind: 'facebook_positions', selected: true },
      { name: 'Search', value: 'search', kind: 'facebook_positions', selected: true },
      { name: 'Instream Video', value: 'instream_video', kind: 'facebook_positions', selected: true },
      { name: 'Facebook Reels', value: 'facebook_reels', kind: 'facebook_positions', selected: true }
    ],
    instagram_positions: [
      { name: 'Stream', value: 'stream', kind: 'instagram_positions', selected: true },
      { name: 'Story', value: 'story', kind: 'instagram_positions', selected: true },
      { name: 'Shop', value: 'shop', kind: 'instagram_positions', selected: true },
      { name: 'Explore', value: 'explore', kind: 'instagram_positions', selected: true },
      { name: 'Explore Home', value: 'explore_home', kind: 'instagram_positions', selected: true },
      { name: 'Reels', value: 'reels', kind: 'instagram_positions', selected: true },
      { name: 'Profile Feed', value: 'profile_feed', kind: 'instagram_positions', selected: true }
    ],
    messenger_positions: [
      { name: 'Messenger Home', value: 'messenger_home', kind: 'messenger_positions', selected: true },
      { name: 'Sponsored Messages', value: 'sponsored_messages', kind: 'messenger_positions', selected: true },
      { name: 'Story', value: 'story', kind: 'messenger_positions', selected: true }
    ],
    publisher_platforms: [
      { name: 'Facebook', value: 'facebook', kind: 'publisher_platforms', selected: true },
      { name: 'Instagram', value: 'instagram', kind: 'publisher_platforms', selected: true },
      { name: 'Audience Network', value: 'audience_network', kind: 'publisher_platforms', selected: true },
      { name: 'Messenger', value: 'messenger', kind: 'publisher_platforms', selected: true }
    ]
  }

  // placementsData = [
  //   { name: 'Desktop', value: 'desktop', kind: 'device_platforms' },
  //   { name: 'Mobile', value: 'mobile', kind: 'device_platforms' },
  //   { name: 'Facebook', value: 'facebook', kind: 'publisher_platforms' },
  //   { name: 'Instagram', value: 'instagram', kind: 'publisher_platforms' },
  //   { name: 'Audience Network', value: 'audience_network', kind: 'publisher_platforms' },
  //   { name: 'Messenger', value: 'messenger', kind: 'publisher_platforms' },
  //   { name: 'Feed', value: 'feed', kind: 'facebook_positions' },
  //   { name: 'Right Hand Column', value: 'right_hand_column', kind: 'facebook_positions' },
  //   { name: 'Marketplace', value: 'marketplace', kind: 'facebook_positions' },
  //   { name: 'Video Feeds', value: 'video_feeds', kind: 'facebook_positions' },
  //   { name: 'Story', value: 'story', kind: 'facebook_positions' },
  //   { name: 'Search', value: 'search', kind: 'facebook_positions' },
  //   { name: 'Instream Video', value: 'instream_video', kind: 'facebook_positions' },
  //   { name: 'Facebook Reels', value: 'facebook_reels', kind: 'facebook_positions' },
  //   { name: 'Stream', value: 'stream', kind: 'instagram_positions' },
  //   { name: 'Story', value: 'story', kind: 'instagram_positions' },
  //   { name: 'Shop', value: 'shop', kind: 'instagram_positions' },
  //   { name: 'Explore', value: 'explore', kind: 'instagram_positions' },
  //   { name: 'Explore Home', value: 'explore_home', kind: 'instagram_positions' },
  //   { name: 'Reels', value: 'reels', kind: 'instagram_positions' },
  //   { name: 'Profile Feed', value: 'profile_feed', kind: 'instagram_positions' },
  //   { name: 'Classic', value: 'classic', kind: 'audience_network_positions' },
  //   { name: 'Rewarded Video', value: 'rewarded_video', kind: 'audience_network_positions' },
  //   { name: 'Messenger Home', value: 'messenger_home', kind: 'messenger_positions' },
  //   { name: 'Sponsored Messages', value: 'sponsored_messages', kind: 'messenger_positions' },
  //   { name: 'Story', value: 'story', kind: 'messenger_positions' }
  // ]

  getExpanded() {
    this.expanded = this.variations.length > 0 ? [this.variations.length - 1] : [0]
  }

  get variations(): placementVariation[] {
    return this.$store.state.launch.generalSetting.adSet.variations.placementVariations
  }

  set variations(value: any) {
    this.$store.state.launch.generalSetting.adSet.variations.placementVariations = value
  }

  get selectedAdAccount() {
    return this.$store.state.account?.adAccount || null
  }

  get isMaxAdSetVariations() {
    return this.$store.getters['launch/isMaxAdSetVariations']
  }

  onPublisherPlatformSelect(platform: any, currentPublishers: boolean, index: number) {
    switch (platform.value) {
      case 'facebook':
        this.variations[index].facebook_positions.map(position => {
          position.selected = currentPublishers
          return position
        })
        break
      case 'instagram':
        this.variations[index].instagram_positions.map(position => {
          position.selected = currentPublishers
          return position
        })
        break
      case 'audience_network':
        this.variations[index].audience_network_positions.map(position => {
          position.selected = currentPublishers
          return position
        })
        break
      case 'messenger':
        this.variations[index].messenger_positions.map(position => {
          position.selected = currentPublishers
          return position
        })
        break
    }
    this.updateGeneralSettings()
  }

  formatNumber(value: string) {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' })
    return formatter.format(Number(value))
  }

  async getReachEstimate() {
    if (!this.selectedAdAccount?.id) return
    try {
      const { data } = await this.$store.state.apiClient.getReachEstimates(
        this.selectedAdAccount.id,
        this.generalTargeting || this.editableValue // TODO: check this
      )
      this.reachEstimate =
        this.formatNumber(data.data.users_lower_bound) + ' - ' + this.formatNumber(data.data.users_upper_bound)
    } catch (error) {
      console.log(error)
    }
  }
  setVariationManual(index, value) {
    this.variations[index].manual = value
    this.updateGeneralSettings()
  }

  appendNewVariation() {
    if (this.isMaxAdSetVariations) {
      this.$emit('max-adset-variations-reached')
      return
    }
    this.variations.push(cloneDeep(this.variationObject))
    this.getExpanded()
    this.updateGeneralSettings()
  }

  removeVariation(index: number) {
    this.variations.splice(index, 1)
    this.updateGeneralSettings()
  }

  updateGeneralSettings() {
    this.$emit('update', this.variations)
  }
}
