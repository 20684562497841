
import { Component, Watch } from 'vue-property-decorator'
import { debounce } from 'lodash'
import ProductCard from '@/modules/product/components/ProductCard.vue'
import PreSelectedProducts from '@/modules/product/components/PreSelectedProducts.vue'
import VueStrong from '@/VueStrong'

@Component({ components: { ProductCard, PreSelectedProducts } })
export default class ProductList extends VueStrong {
  loading = false
  snackbarSuccess = false
  snackbarError = false
  selectedTemplate = null

  productVisibility = 'private'
  searchQuery = ''

  customers = []
  loadingCustomers = false
  selectedCustomer = null
  customerQuery = null

  debounceGetProducts = debounce(this.getProducts, 500)
  debounceGetCustomers = debounce(this.getCustomers, 500)

  get selectedProducts() {
    return this.$store.state.launch.selectedProducts || []
  }

  get products() {
    return this.$store.state.product.products || []
  }

  get params() {
    return this.$store.state.product.params
  }

  get pageCount() {
    return Math.ceil(this.params.serverItemsLength / this.params.itemsPerPage) || 0
  }

  /*
  @Watch('customerQuery')
  searchCustomer() {
    console.log('this.customerQuery', this.customerQuery)
    console.log('this.selectedCustomer', this.selectedCustomer)
    this.debounceGetCustomers()
  }
  */

  async created() {
    this.searchQuery = this.params.sku || ''
    await this.updateParams({ ...this.params, productVisibility: this.productVisibility })
  }

  async getCustomers() {
    if (!this.customerQuery || this.loadingCustomers) return

    this.loadingCustomers = true
    try {
      const { data } = await this.$store.state.apiClient.getCustomers({ customer: this.customerQuery })
      this.customers = data.customers
    } catch (error) {
      console.log(error)
      this.snackbarError = true
    }
    this.loadingCustomers = false
  }

  async getProducts() {
    this.loading = true
    await this.$store.dispatch('product/getProducts')
    this.loading = false
  }

  async updateParams(newParams: any): Promise<void> {
    this.$store.dispatch('product/setParams', newParams)
    await this.debounceGetProducts()
  }

  async updatePage(page: number): Promise<void> {
    await this.updateParams({ ...this.params, page })
  }

  async updateItemsPerPage(itemsPerPage: number): Promise<void> {
    await this.updateParams({ ...this.params, itemsPerPage, page: 1 })
  }

  get submitted() {
    return this.$store.state.launch.steps.products.submitted
  }

  @Watch('submitted')
  onSubmit() {
    if (this.submitted) {
      const valid = this.selectedProducts.length > 0
      this.$store.commit('launch/setValidStep', { type: 'products', value: { submitted: false, valid } })
      if (valid) this.$store.commit('launch/setStep', 2)
      else this.$store.commit('launch/setChangeStepError', 'products')
    }
  }
}
