
import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import TemplateCardCampaign from '@/modules/campaign/components/TemplateCardCampaign.vue'

@Component({ components: { TemplateCardCampaign } })
export default class SelectLaunchType extends VueStrong {
  @Prop({ required: true }) value!: string | null

  selectedIndex = this.value !== null ? (this.value === 'template' ? 1 : 0) : undefined
  loading = false

  newTemplateSave = false
  newTemplateName = ''

  onTemplateNameChange() {
    this.$store.commit('launch/setTemplateName', this.newTemplateName)
  }

  onSaveTemplateChange() {
    this.$store.commit('launch/setTemplateName', this.newTemplateSave ? this.newTemplateSave : '')
  }

  get templatesDrawer() {
    return this.$store.state.launch.showTemplates
  }

  set templatesDrawer(value: boolean) {
    this.$store.commit('launch/setShowTemplates', value)
  }

  get generalSettings() {
    return this.$store.state.launch.generalSetting
  }

  get isConfirmButtonDisabled() {
    return this.selectedIndex === undefined || (this.selectedIndex === 1 && !(this.generalSettings as any)._id)
  }

  @Watch('selectedIndex')
  onSelectedIndexChange() {
    if (this.selectedIndex === 1) this.$store.commit('launch/setShowTemplates', true)
  }

  mounted() {
    this.getTemplates()
  }

  onButtonClick(template?: yakkyo.IFacebookAdTemplate) {
    console.log('template', template)
    if (this.selectedIndex === null) return
    this.templatesDrawer = false
    this.$emit('input', this.selectedIndex === 1 ? 'template' : 'campaign')

    if (!template) return

    const baseSettings = {
      importStatus: 0,
      importError: '',
      specificSettingsOn: {
        campaign: false,
        targeting: false,
        adCreative: false,
        adSet: false
      },
      page: ''
    }
    this.$store.dispatch('launch/updateGeneralSetting', { ...template, ...baseSettings })
    this.$store.commit('launch/updateVariations', template)
  }

  get templates() {
    return this.$store.state.template.templates.campaign
  }

  async getTemplates() {
    this.loading = true
    await this.$store.dispatch('template/getTemplates', 'campaign')
    this.loading = false
  }
}
