
import { Component, Prop } from 'vue-property-decorator'
import _ from 'lodash'
import VueStrong from '@/VueStrong'

type Cursors = {
  after: string
  before: string
}

@Component({ components: {} })
export default class FacebookMedia extends VueStrong {
  @Prop({ required: true }) productId!: string

  loading = false
  error = null
  imagesCursors: Cursors = { after: '', before: '' }
  videosCursors: Cursors = { after: '', before: '' }

  images = []
  videos = []

  imgDialog = null
  vidDialog = null

  get adAccountId() {
    return this.$store.state.account.adAccount?.id
  }

  get smAndDown() {
    return this.$vuetify.breakpoint.smAndDown
  }

  get allSelectedAdImages() {
    return this.$store.state.launch.selectedAdImages
  }

  get allSelectedAdVideos() {
    return this.$store.state.launch.selectedAdVideos
  }

  get selectedAdImages() {
    return this.$store.state.launch.selectedAdImages.filter(i => i.product === this.productId)
  }

  get selectedAdVideos() {
    return this.$store.state.launch.selectedAdVideos.filter(v => v.product === this.productId)
  }

  created() {
    this.getFbImages()
    this.getFbVideos()
  }

  getImageIcon(width: number, height: number) {
    if (width > height) return 'mdi-arrow-left-right'
    if (width < height) return 'mdi-arrow-up-down'
    return 'mdi-arrow-expand-all'
  }

  async getFbImages(action?: 'next' | 'prev') {
    try {
      if (!this.adAccountId) return
      this.loading = true

      const params = {}
      if (action === 'next') params['after'] = this.imagesCursors.after
      else if (action === 'prev') params['before'] = this.imagesCursors.before

      const { data } = await this.$store.state.apiClient.getFbAdImages(this.adAccountId, params)

      this.images = data.data
      this.imagesCursors = data.cursors
    } catch (error) {
      this.error = error.response?.data?.error || error.message || error
    }
    this.loading = false
  }

  async getFbVideos(action?: 'next' | 'prev') {
    try {
      if (!this.adAccountId) return
      this.loading = true

      const params = {}
      if (action === 'next') params['after'] = this.videosCursors.after
      else if (action === 'prev') params['before'] = this.videosCursors.before

      const { data } = await this.$store.state.apiClient.getFbAdVideos(this.adAccountId, params)

      this.videos = data.data
      this.videosCursors = data.cursors
    } catch (error) {
      this.error = error.response?.data?.error || error.message || error
    }
    this.loading = false
  }

  onImgSelect(img: any) {
    const selected = _.cloneDeep(this.allSelectedAdImages)
    const selectedIndex = this.selectedAdImages.findIndex(i => i.id === img.id)
    if (selectedIndex < 0) selected.push({ ...img, product: this.productId })
    else selected.splice(selectedIndex, 1)
    this.$store.dispatch('launch/setSelectedAdPictures', selected)
  }

  isImgSelected(img: any) {
    const selectedIndex = this.selectedAdImages.findIndex(i => i.id === img.id)
    if (selectedIndex < 0) return false
    else return true
  }

  onVidSelect(video: any) {
    const selected = _.cloneDeep(this.allSelectedAdVideos)
    const selectedIndex = this.selectedAdVideos.findIndex(i => i.id === video.id)
    if (selectedIndex < 0) selected.push({ ...video, product: this.productId })
    else selected.splice(selectedIndex, 1)
    this.$store.dispatch('launch/setSelectedAdVideos', selected)
  }

  isVidSelected(img: any) {
    const selectedIndex = this.selectedAdVideos.findIndex(i => i.id === img.id)
    if (selectedIndex < 0) return false
    else return true
  }
}
