
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component({})
export default class ProductCard extends VueStrong {
  @Prop({ required: true }) product: yakkyo.IProduct

  get selectedProducts() {
    return this.$store.state.launch.selectedProducts || []
  }

  get isSelected() {
    return !!this.selectedProducts.find(p => p._id === this.product._id)
  }

  toggleProduct() {
    if (!this.isSelected) return this.$store.dispatch('launch/selectProduct', this.product)
    return this.$store.dispatch('launch/unselectProduct', this.product)
  }
}
