
import { Component, Prop, PropSync } from 'vue-property-decorator'
import AdCreativeForm from '@/modules/adcreative/components/AdCreativeForm.vue'
import AdCreativeVariationsBanner from '@/modules/adcreative/components/AdCreativeVariationsBanner.vue'
import SettingsCard from '@/components/SettingsCard.vue'
import { callToActions } from '@/helpers/data'
import VueStrong from '@/VueStrong'
@Component({
  components: {
    SettingsCard,
    AdCreativeForm,
    AdCreativeVariationsBanner
  }
})
export default class AdCreativeGeneralForm extends VueStrong {
  @PropSync('value', { type: Object }) variation!: any
  @Prop({ required: true }) index!: number

  get campaignObjective() {
    return this.$store.state.launch.generalSetting.campaign.objective
  }

  get buttonLabels() {
    return callToActions.filter(a => a.picture.includes(this.campaignObjective as string))
  }

  updateVariationStatus(status: string) {
    this.variation.status = status
    this.$emit('update', this.variation, this.index)
  }
  updateVariationCTA(value: string) {
    this.variation.callToAction = value
    this.$emit('update', this.variation, this.index)
  }

  dispatchSettingUpdate(val) {
    const { link, title, body, description } = val
    this.variation = { ...this.variation, link, title, body, description }
    this.$emit('update', this.variation, this.index)
  }
}
