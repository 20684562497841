
import axios from 'axios'
import { Component, Prop } from 'vue-property-decorator'
import SettingsCard from '@/components/SettingsCard.vue'
import VueStrong from '@/VueStrong'

@Component({ components: { SettingsCard } })
export default class ProductPictures extends VueStrong {
  @Prop({ required: true }) product: yakkyo.IProduct

  file = null
  uploadDialog = false

  loading = false
  snackbarSuccess = false
  snackbarError = false
  uploadError = false

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }

  get selectedProducts() {
    return this.$store.state.launch.selectedProducts || []
  }

  get selectedPictures(): { product: string; src: string }[] {
    return this.$store.state.launch.selectedPictures || []
  }

  get bulkToggleSelect() {
    return this.allPicturesSelected ? 'unselect all' : 'select all'
  }

  get allPicturesSelected() {
    let res = true
    this.product.pictures.forEach(productPic => {
      if (!this.selectedPictures.some(selectedPic => selectedPic.src === productPic)) res = false
    })
    return res
  }

  async onFileChanged(file, productId: string) {
    try {
      if (!file) return
      this.loading = true
      const formData = new FormData()
      formData.append('file', file)
      const { data } = await axios.post(`${process.env.VUE_APP_MEDIA_BASE_URL}/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': this.$store.state.token }
      })

      this.$store.dispatch('launch/addProductPicture', { productId, src: data.result.url })
      this.snackbarSuccess = true
    } catch (error) {
      console.log(error)
      this.uploadError = true
      this.snackbarError = true
    }
    file = null
    this.loading = false
  }

  closeUploadDialog() {
    this.uploadDialog = false
    this.uploadError = false
  }

  toggleSelectAll(product: yakkyo.IProduct) {
    if (this.allPicturesSelected) {
      // unselect all
      const selectedPics = this.selectedPictures.filter(p => !product.pictures.includes(p.src))
      this.$store.dispatch('launch/setSelectedPictures', selectedPics)
    } else {
      // select all

      const productPics = product.pictures.map(p => ({ product: product._id, src: p }))

      let selected = [...productPics, ...this.selectedPictures].map(p => JSON.stringify(p))
      selected = [...new Set([...selected])].map(s => JSON.parse(s))

      this.$store.dispatch('launch/setSelectedPictures', selected)
    }
  }

  isSelectedPicture(picture: string) {
    return !!this.selectedPictures.find(p => p.src === picture)
  }

  selectPicture(picture: string, product: string) {
    const selectedPictures = [...new Set([...this.selectedPictures, { src: picture, product }])]
    this.$store.dispatch('launch/setSelectedPictures', selectedPictures)
  }

  unselectPicture(picture: string) {
    const selectedPictures = [...this.selectedPictures.filter(p => p.src !== picture)]
    this.$store.dispatch('launch/setSelectedPictures', selectedPictures)
  }
}
