
import NavMenu from '@/components/NavMenu.vue'
import SettingsCard from '@/components/SettingsCard.vue'
import AdCreativeForm from '@/modules/adcreative/components/AdCreativeForm.vue'
import AdCreativeGeneralForm from '@/modules/adcreative/components/AdCreativeGeneralForm.vue'
import AdCreativeUtm from '@/modules/adcreative/components/AdCreativeUtm.vue'
import AdCreativeVariationsBanner from '@/modules/adcreative/components/AdCreativeVariationsBanner.vue'
import TemplateCardAdCreative from '@/modules/adcreative/components/TemplateCardAdCreative.vue'
import SpecificSettings from '@/modules/launch/components/SpecificSettings.vue'
import SettingsMixin from '@/modules/launch/mixins/SettingsMixin.vue'
import { cloneDeep } from 'lodash'
import { Component, Watch } from 'vue-property-decorator'

@Component({
  components: {
    TemplateCardAdCreative,
    SettingsCard,
    SpecificSettings,
    AdCreativeForm,
    AdCreativeUtm,
    NavMenu,
    AdCreativeVariationsBanner,
    AdCreativeGeneralForm
  },
  mixins: [SettingsMixin]
})
export default class StepAdCreative extends SettingsMixin {
  valid = true
  required = [v => !!v || '*Required']

  editingSection = 'adCreative'
  editableFields = ['adCreative.generalVariations', 'adCreative.urlTagsVariations']

  activeTab = 0
  expanded: number[] = [0]
  expandedUrlTag: number[] = [0]

  generalVariationObject = {
    name: '',
    status: 'ACTIVE',
    link: '',
    title: '',
    description: '',
    body: '',
    callToAction: ''
  }
  urlVariationObject = {
    utm_medium: '{{placement}}',
    utm_campaign: '{{campaing.name}}',
    utm_source: 'cpc',
    utm_term: '{{ad.id}}',
    utm_content: '{{ad.name}}'
  }

  get adCreativeMenuItems() {
    return [
      { title: 'General', icon: 'mdi-cog', variations: this.generalVariations.length },
      { title: 'UTM params', icon: 'mdi-file-document-edit-outline', variations: this.urlTagsVariations.length }
    ]
  }

  get templatesDrawer() {
    return this.$store.state.launch.showTemplates
  }

  set templatesDrawer(value: boolean) {
    this.$store.commit('launch/setShowTemplates', value)
  }

  get generalVariations() {
    return this.$store.state.launch.generalSetting.adCreative.generalVariations
  }
  get urlTagsVariations() {
    return this.$store.state.launch.generalSetting.adCreative.urlTagsVariations
  }

  onTemplateSelected(template: yakkyo.IFacebookAdTemplateAdCreative) {
    this.editableSettings = cloneDeep({
      ...this.editableSettings,
      adCreative: {
        ...(this.editableSettings.adCreative || {}),
        ...template
      }
    })
    this.dispatchSettingUpdate()
    this.$store.commit('launch/setStep', 5)
  }

  get isValid() {
    return this.$store.state.launch.steps.adCreative.valid
  }

  @Watch('isValid')
  changeTab() {
    if (!this.isValid) this.activeTab = 0
  }

  get templates() {
    return this.$store.state.template.templates.adcreative
  }

  mounted() {
    this.$store.dispatch('template/getTemplates', 'adcreative')
  }

  get isMaxAdCreativeVariations() {
    return this.$store.getters['launch/isMaxAdCreativeVariations']
  }
  updateGeneralVariations(variation: any, index: any) {
    this.generalVariations[index] = variation
    this.updateGeneralSettings()
  }

  updateUrlTagsVariations(variation: any, index: any) {
    this.urlTagsVariations[index] = variation
    this.updateUrlTagsSettings()
  }

  appendNewGeneralVariation() {
    if (this.isMaxAdCreativeVariations) {
      this.$emit('max-adcreative-variations-reached')
      return
    }
    this.generalVariations.push(cloneDeep(this.generalVariationObject))
    this.getExpanded()
    this.updateGeneralSettings()
  }

  appendNewUrlTagsVariation() {
    if (this.isMaxAdCreativeVariations) {
      this.$emit('max-adcreative-variations-reached')
      return
    }
    this.urlTagsVariations.push(cloneDeep(this.urlVariationObject))
    this.getExpandedUrlTag()
    this.updateUrlTagsSettings()
  }
  getExpanded() {
    this.expanded = this.generalVariations.length > 0 ? [this.generalVariations.length - 1] : [0]
  }
  getExpandedUrlTag() {
    this.expandedUrlTag = this.urlTagsVariations.length > 0 ? [this.urlTagsVariations.length - 1] : [0]
  }
  removeGeneralVariation(index: number) {
    this.generalVariations.splice(index, 1)
    this.updateGeneralSettings()
  }
  removeUrlTagsVariation(index: number) {
    this.urlTagsVariations.splice(index, 1)
    this.updateUrlTagsSettings()
  }
  updateGeneralSettings() {
    this.editableSettings.adCreative.generalVariations = this.generalVariations
    this.dispatchSettingUpdate()
  }
  updateUrlTagsSettings() {
    this.editableSettings.adCreative.urlTagsVariations = this.urlTagsVariations
    this.dispatchSettingUpdate()
  }
}
