
import { Component, Prop, PropSync } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import NumberInput from '@/components/NumberInput.vue'
import SectionBanner from '@/components/SectionBanner.vue'
import { cloneDeep } from 'lodash'
import { ageVariation } from '@/modules/launch/store/state'

@Component({ components: { NumberInput, SettingsCard, SectionBanner } })
export default class TargetForm extends VueStrong {
  @PropSync('value', { type: Object }) editableValue: Partial<yakkyo.IFacebookAdTemplateAdSet['targeting']>
  @Prop({ default: null }) generalTargeting!: yakkyo.IFacebookAdTemplateAdSet['targeting'] | null
  @Prop({ default: null }) product!: yakkyo.IProduct | null // TODO: should it be a string?
  reachEstimate = ''
  expanded = [0]
  variationObject = {
    genders: {
      male: true,
      female: true
    },
    age_max: 65,
    age_min: 13
  }

  get selectedAdAccount() {
    return this.$store.state.account?.adAccount || null
  }

  get variations(): ageVariation[] {
    return this.$store.state.launch.generalSetting.adSet.variations.ageVariations
  }

  get isMaxAdSetVariations() {
    return this.$store.getters['launch/isMaxAdSetVariations']
  }

  getExpanded() {
    this.expanded = this.variations.length > 0 ? [this.variations.length - 1] : [0]
  }

  formatNumber(value: string) {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' })
    return formatter.format(Number(value))
  }

  async getReachEstimate() {
    if (!this.selectedAdAccount?.id) return
    try {
      const { data } = await this.$store.state.apiClient.getReachEstimates(
        this.selectedAdAccount.id,
        this.generalTargeting || this.editableValue // TODO: check this
      )
      this.reachEstimate =
        this.formatNumber(data.data.users_lower_bound) + ' - ' + this.formatNumber(data.data.users_upper_bound)
    } catch (error) {
      console.log(error)
    }
  }

  appendNewVariation() {
    if (this.isMaxAdSetVariations) {
      this.$emit('max-adset-variations-reached')
      return
    }
    this.variations.push(cloneDeep(this.variationObject))
    this.getExpanded()
    this.updateGeneralSettings()
  }

  removeVariation(index: number) {
    this.variations.splice(index, 1)
    this.updateGeneralSettings()
  }
  updateMinAge(value, i) {
    this.variations[i].age_min = Number(value)
    this.updateGeneralSettings()
  }
  updateMaxAge(value, i) {
    this.variations[i].age_max = Number(value)
    this.updateGeneralSettings()
  }
  updateGeneralSettings() {
    this.$emit('update', this.variations)
  }
}
