
import { Component, Watch } from 'vue-property-decorator'
import SettingsMixin from '@/modules/launch/mixins/SettingsMixin.vue'
import NavMenu from '@/components/NavMenu.vue'
import SpecificSettings from '@/modules/launch/components/SpecificSettings.vue'
import TemplateCardAdSet from '@/modules/adset/components/TemplateCardAdSet.vue'
import AdSetVariationsBanner from '@/modules/adset/components/AdSetVariationsBanner.vue'
import AdSetGeneralForm from '@/modules/adset/components/AdSetGeneralForm.vue'
import AgeGenderForm from '@/modules/adset/components/AgeGenderForm.vue'
import PlacementsForm from '@/modules/adset/components/PlacementsForm.vue'
import TargetForm from '@/modules/adset/components/TargetForm.vue'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    SpecificSettings,
    TemplateCardAdSet,
    AdSetGeneralForm,
    PlacementsForm,
    AgeGenderForm,
    AdSetVariationsBanner,
    TargetForm,
    NavMenu
  },
  mixins: [SettingsMixin]
})
export default class StepAdSet extends SettingsMixin {
  valid = true
  activeTab = 0

  get templatesDrawer() {
    return this.$store.state.launch.showTemplates
  }

  set templatesDrawer(value: boolean) {
    this.$store.commit('launch/setShowTemplates', value)
  }

  get campaign() {
    return this.$store.state.launch.generalSetting.campaign
  }

  editingSection = 'adSet'
  editableFields = [
    'adSet.name',
    'adSet.budgetWindow',
    'adSet.budget',
    'adSet.bid_strategy',
    'adSet.start_time',
    'adSet.end_time',
    'adSet.optimization_goal',
    'adSet.bid_amount',
    'adSet.spend_cap',
    'adSet.billing_event',
    'adSet.conversion_event',
    'adSet.attribution_spec',
    'adSet.status',
    'adSet.numberOfVariations',
    'adSet.variations',

    // TODO: add targeting fields
    'adSet.targeting.interests',
    'adSet.targeting.locales',
    'adSet.targeting.geo_locations.countries',
    'adSet.targeting.age_min',
    'adSet.targeting.age_max',
    'adSet.targeting.genders',
    'adSet.targeting.placements'
  ]

  ageVariationNumber = 1
  audienceVariationNumber = 1
  placementsVariationNumber = 1

  get totalAgeVariations() {
    return this.$store.state.launch.generalSetting.adSet.variations.ageVariations.length
  }
  get totalPlacementVariations() {
    return this.$store.state.launch.generalSetting.adSet.variations.placementVariations.length
  }
  get totalAudienceVariations() {
    return this.$store.state.launch.generalSetting.adSet.variations.audienceVariations.length
  }

  get adSetMenuItems() {
    return [
      { title: 'General', icon: 'mdi-cog', variations: null },
      { title: 'Target Audience', icon: 'mdi-bullseye-arrow', variations: this.totalAudienceVariations },
      { title: 'Age/Gender', icon: 'mdi-gender-male-female', variations: this.totalAgeVariations },
      { title: 'Placements', icon: 'mdi-responsive', variations: this.totalPlacementVariations }
    ]
  }

  get submitted() {
    return this.$store.state.launch.steps.adSet.submitted
  }

  get validationTrigger() {
    return this.$store.state.launch.validationTrigger
  }

  get applySettingsFor() {
    return this.$store.state.launch.applySettingsFor
  }

  @Watch('submitted')
  onSubmit() {
    if (this.submitted) {
      const valid = (this.$refs.adsetsForm as any).validate()
      this.$store.commit('launch/setValidStep', { type: 'adSet', value: { submitted: false, valid } })
      if (!valid) {
        this.activeTab = 0
        this.$store.commit('launch/setChangeStepError', 'adSet')
        if (!!this.applySettingsFor.productId && this.validationTrigger === 'applySettingsFor')
          this.$store.commit('launch/setApplySettingsFor', { entity: this.applySettingsFor.entity, productId: '' })
      } else {
        if (this.validationTrigger === 'stepChange') this.$store.commit('launch/setStep', 4)
        if (!!this.applySettingsFor.productId && this.validationTrigger === 'applySettingsFor')
          this.$store.commit('launch/setApplySettingsFor', { entity: this.applySettingsFor.productId, productId: '' })
      }
    }
  }

  get templates() {
    return this.$store.state.template.templates.adset
  }

  mounted() {
    this.$store.dispatch('template/getTemplates', 'adset')
  }

  updateGeneralSettings(variation) {
    this.editableSettings.adSet = { ...this.editableSettings.adSet, ...variation }
    this.dispatchSettingUpdate()
  }

  updateTargetVariationSettings(variation) {
    this.editableSettings.adSet['variations']['audienceVariations'] = variation
    this.dispatchSettingUpdate()
  }
  updateAgeVariationSettings(variation) {
    this.editableSettings.adSet['variations']['ageVariations'] = variation
    this.dispatchSettingUpdate()
  }
  updatePlacementVariationSettings(variation) {
    this.editableSettings.adSet['variations']['placementVariations'] = variation
    this.dispatchSettingUpdate()
  }

  onTemplateSelected(template: yakkyo.IFacebookAdTemplateAdSet) {
    this.editableSettings = cloneDeep({
      ...this.editableSettings,
      adSet: {
        ...this.editableSettings.adSet,
        ...template
      }
    })
    this.dispatchSettingUpdate()
  }
}
