
import { Component, Watch } from 'vue-property-decorator'
import SettingsMixin from '@/modules/launch/mixins/SettingsMixin.vue'
import ShopSelect from './ShopSelect.vue'
import SettingsCard from '@/components/SettingsCard.vue'
import SpecificSettings from '@/modules/launch/components/SpecificSettings.vue'
import CampaignObjective from '@/modules/campaign/components/CampaignObjective.vue'
import CampaignBudget from '@/modules/campaign/components/CampaignBudget.vue'
import NumberInput from '@/components/NumberInput.vue'

@Component({
  mixins: [SettingsMixin],
  components: { ShopSelect, SettingsCard, SpecificSettings, CampaignObjective, CampaignBudget, NumberInput }
})
export default class StepCampaignVariation extends SettingsMixin {
  statuses = ['PAUSED', 'ACTIVE']
  editingSection = 'campaign'
  editableFields = [
    'campaign.conversion_domain',
    'campaign.objective',
    'campaign.name',
    'campaign.status',
    'campaign.bid_strategy',
    'campaign.budgetWindow',
    'campaign.budget',
    'campaign.cbo',
    'campaign.labels',
    'adSet.pixel',
    'adSet.instagramAccount',
    'page'
  ]

  valid = true
  missingObjective = false
  changeStepError = ''

  required = [v => !!v || '*Required']
  domainRules = [
    v =>
      /(((?:[a-z\d](?:[a-z\d-]{0,63}[a-z\d])?|\*)\.)+[a-z\d][a-z\d-]{0,63}[a-z\d]|^\s*$)/.test(v) ||
      'Not valid. Domain should look like yourDomain.com'
  ]

  isAdsetVariationDisabled = false
  isAdCreativeVariationDisabled = false

  get adSetVariations() {
    return this.$store.state.launch.adSetVariations
  }
  set adSetVariations(newValue: number) {
    this.$store.commit('launch/setAdSetVariations', newValue)
  }

  get adCreativeVariations() {
    return this.$store.state.launch.adCreativeVariations
  }

  set adCreativeVariations(newValue: number) {
    this.$store.commit('launch/setAdCreativeVariations', newValue)
  }

  get pages() {
    return this.$store.getters['account/pages']
  }

  get pixels() {
    return this.$store.getters['account/pixels']
  }

  get instagramAccounts() {
    return this.$store.getters['account/instagramAccounts']
  }
  get campaignStatus() {
    return this.$store.state.launch.generalSetting.campaign.status.toLowerCase() === 'active' ? true : false
  }
  set campaignStatus(newValue: boolean) {
    this.editableSettings.campaign.status = newValue ? 'ACTIVE' : 'PAUSED'
  }

  changeCampaignStatus(value: boolean) {
    this.editableSettings.campaign.status = value ? 'ACTIVE' : 'PAUSED'
    this.dispatchSettingUpdate()
  }

  get submitted() {
    return this.$store.state.launch.steps.campaign.submitted
  }

  get validationTrigger() {
    return this.$store.state.launch.validationTrigger
  }

  get applySettingsFor() {
    return this.$store.state.launch.applySettingsFor
  }

  mounted() {
    if (this.adSetVariations > 1) {
      this.isAdsetVariationDisabled = true
    }
    if (this.adCreativeVariations > 1) {
      this.isAdCreativeVariationDisabled = true
    }
  }

  @Watch('submitted')
  onSubmit() {
    if (this.submitted) {
      if (this.editableSettings.campaign.objective) this.missingObjective = false
      else this.missingObjective = true
      const valid = (this.$refs.campaignForm as any).validate() && !this.missingObjective
      this.$store.commit('launch/setValidStep', { type: 'campaign', value: { submitted: false, valid } })
      if (valid) {
        if (this.validationTrigger === 'stepChange') this.$store.commit('launch/setStep', 3)
        if (!!this.applySettingsFor.productId && this.validationTrigger === 'applySettingsFor')
          this.$store.commit('launch/setApplySettingsFor', { entity: this.applySettingsFor.productId, productId: '' })
      } else {
        if (!!this.applySettingsFor.productId && this.validationTrigger === 'applySettingsFor')
          this.$store.commit('launch/setApplySettingsFor', { entity: this.applySettingsFor.entity, productId: '' })
        this.$store.commit('launch/setChangeStepError', 'campaign')
      }
    }
  }
}
