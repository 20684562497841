
import { Component } from 'vue-property-decorator'
import ProductCard from '@/modules/product/components/ProductCard.vue'
import VueStrong from '@/VueStrong'

@Component({ components: { ProductCard } })
export default class PreSelectedProducts extends VueStrong {
  editSelected = false

  get products() {
    return this.$store.state.launch.preSelectedProduct
  }

  get selectedProducts() {
    return this.$store.state.launch.selectedProducts || []
  }

  get skus() {
    const res = this.selectedProducts.map(p => p.yakkyofySku)
    return res.length ? res.join(', ') : '-'
  }
}
